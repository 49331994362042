// src/config/path/index.ts
export const PATH = {
  HOME: '/',
  INDEX: '/',
  CONTACT: '/contact',
  SERVICES: '/services',
  SUPPORT: '/support',
  AUDIT: '/support/audit',
  WEBMASTER: '/support/webmaster',
  TOS: '/terms-of-service',
  MENTIONLEGAL: '/mentions-legales',
  PRIVACYP: '/privacy-policy',
  COOKIEP: '/cookie-policy',
  LEGALNOTICE: '/legal-notice',
  DATAPROTECH: '/data-protection-policy',
  USERAGREE: '/user-agreement',
  DISCLAMER: '/disclaimers',
  TABLEM: '/table-des-matieres',
};
