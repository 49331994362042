// src/ui/layout/ServicesDropdown.jsx
'use client';
import { useState, useRef } from 'react';
import dynamic from 'next/dynamic';
import { PATH } from '@/config/path';
import useDropdown from '@/hook/useDropdown';
import styles from './Navbar.module.css';

const CityLink = dynamic(() => import('@/server/link/CityLink'), {
  ssr: false,
});

const ServicesDropdown = () => {
  const { showDropdown, dropdownRef, handleMouseEnter, handleMouseLeave } = useDropdown();

  return (
    <div className={styles.link} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={dropdownRef}>
      <CityLink href={PATH.SERVICES} passHref>
        <span aria-label='services'>Services</span>
      </CityLink>
      <div className={`${styles.dropdown} ${showDropdown ? styles.show : ''}`} role='menu'>
        <ul className={styles.dropdown}>
          <li role='menuitem'>
            <CityLink href={PATH.SUPPORT} passHref>
              <span aria-label='Support'>Support</span>
            </CityLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ServicesDropdown;
