'use client';
import { useRef, useState } from 'react';
import DesertChrome from '@/server/title/DesertChrome';
import CrossIcon from '@/svg/CrossIcon1';
import HamburgerIcon from '@/svg/HamburgerIcon1';
import IntLink from '@/server/link/IntLink';
import { PATH } from '@/config/path';
import AsideNavMenu from './AsideNavMenu';
import Logo40 from '@/img/Logo40';
import useEscape from '@/hook/key/useEscape';
import useOutside from '@/hook/click/useOutside';
import ServicesDropdown from './ServicesDropdown';
import { TITLE } from '@/config/siteConfig';
import Phone from '@/server/contact/Phone';
import NavButton from '@/client/input/NavButton';
import styles from './Navbar.module.css';
import dynamic from 'next/dynamic';

const CityLink = dynamic(() => import('@/server/link/CityLink'), {
  ssr: false,
});

const Navbar = () => {
  const [showAside, setShowAside] = useState(false);
  const asideRef = useRef();

  useEscape(() => setShowAside(false));
  useOutside(asideRef, () => setShowAside(false));

  const toggleAside = () => setShowAside((prevState) => !prevState);

  const iconStyle = showAside ? 'rotateIn' : 'grow';

  return (
    <nav className={styles.navbar} aria-label='navigation'>
      <div className={styles.navItem}>
        <IntLink href={PATH.HOME} passHref>
          <span className={styles.title}>
            <div className={styles.logo}>
              <Logo40 alt={`Logo of ${TITLE}`} />
            </div>
            <div className={styles.textTitle}>
              <DesertChrome />
            </div>
          </span>
        </IntLink>
      </div>
      <div className={styles.navLinks}>
        <ServicesDropdown />
        <CityLink href={PATH.CONTACT} passHref>
          <span className={styles.link}>Contact</span>
        </CityLink>
        <Phone phoneKey='DEAGLE' />
      </div>
      <div className={styles.rightSection}>
        <div>
          <NavButton />
        </div>
        <div
          className={styles.hamburger}
          onClick={toggleAside}
          aria-label='menuToggle'
          role='button'
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && toggleAside()}
        >
          <div className={iconStyle}>{showAside ? <CrossIcon /> : <HamburgerIcon />}</div>
        </div>
      </div>
      <AsideNavMenu ref={asideRef} className={styles.aside} isOpen={showAside} onClose={() => setShowAside(false)} />
    </nav>
  );
};

export default Navbar;
