// src/ui/server/contact/Phone.ts
import React from 'react';
import { PHONE } from '@/config/phone';
import FollowLink from '@/ui/server/link/FollowLink';

interface PhoneProps {
  phoneKey: keyof typeof PHONE;
  className?: string;
}

const Phone: React.FC<PhoneProps> = ({ phoneKey, className }) => {
  if (!(phoneKey in PHONE)) {
    throw new Error(`Unknown phoneKey: ${phoneKey}`);
  }

  const { name, number, formatted } = PHONE[phoneKey];

  return (
    <address className={className} aria-label={`Address of ${name}, formatted as ${formatted}`}>
      <FollowLink href={`tel:${number}`} ariaLabel={`Call ${name} at ${formatted}`}>
        <span role='img' aria-hidden='true' aria-label={`Phone icon for ${name}`}>
          📞
        </span>
        {formatted}
      </FollowLink>
    </address>
  );
};

export default Phone;
